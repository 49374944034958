import React from 'react';
import './App.scss';
import { BrowserRouter as Router, Route } from "react-router-dom";
import IndexPage from './components/indexPage';
import Sidebar from './components/sidebar';
import axios from "axios";
import PrinterPage from './components/printerPage';
import NewPrinterPage from './components/newPrinterPage';
import Warning from './components/warning';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Socket, EVENT_DISCONNECTED, EVENT_CONNECTED } from "./socket";
import { API_BASE } from './constants';

class App extends React.Component {
  state = {
    printers: [ ],
    disconnected: false,
    socket: null
  }

  componentDidMount() {
    this.connect()
  }

  connect() {
    this.updatePrinters()
    
    this.setState({ disconnected: false })

    let socket = new Socket(`${window.location.protocol === "https:" ? "wss" : "ws"}://${process.env.REACT_APP_API_BASE || window.location.host}/events`)

    socket.on(EVENT_CONNECTED, () => {
      toast.success("Connected to printemu")
    })

    socket.on(EVENT_DISCONNECTED, () => {
      if(this.state.disconnected) return // debounce
      toast.error("Disconnected from printemu")
      this.setState({ disconnected: true })
    })

    socket.on("printer_state", updatedPrinter => {
      if(this.state.printers.length === 0) return

      let printers = this.state.printers
      let printer = printers.filter(p => p.id === updatedPrinter.id)[0]
      
      printer.state = updatedPrinter.state
      this.setState({ printers })
    })

    socket.on("printer_created", newPrinter => {
      this.state.printers.push(newPrinter)
      toast.info(`Printer ${newPrinter.state.machine_name} created`)
      this.setState({ printers: this.state.printers })
    })

    socket.on("printer_removed", deletedId => {
      let ind = this.state.printers.indexOf(this.state.printers.filter(p => p.id === deletedId)[0])
      toast.info(`Printer ${this.state.printers[ind].state.machine_name} removed`)
      console.log(ind)
      this.state.printers.splice(ind, 1)
      this.setState({ printers: this.state.printers })
    })

    this.setState({ socket })
  }

  async updatePrinters() {
    const { data: printers } = await axios.get(`${API_BASE}/api/printers`)
    this.setState({ printers })
  }

  render() {
    let sidebarItems = this.state.printers.map(printer => {
      return {
        id: printer.id,
        title: printer.state.machine_name,
        to: `/printers/${printer.id}`
      }
    })

    return (
      <Router>
        <div className="App">
          {this.state.disconnected && <Warning>Warning: You are disconnected from printemu. Refresh or click <a onClick={this.connect.bind(this)}>here</a> to reconnect.</Warning>}
          <Sidebar sidebarItems={sidebarItems}/>
          <div className="content">
            <Route
              path="/"
              exact
              render={ props => <IndexPage {...props} printers={this.state.printers}/> } />
            <Route path="/new" exact component={NewPrinterPage} />
            <Route
              path="/printers/:id"
              render={ props => <PrinterPage {...props} printers={this.state.printers}/> } />
          </div>
        </div>
        <div>
        <ToastContainer
          position="bottom-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop
          closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          draggable
          pauseOnHover
          theme="light"
        />
        </div>
      </Router>
    )
  }
}

export default App;
