import React from 'react';
import "./warning.scss";

class Warning extends React.Component {
  render() {
    return (
      <div className="warning">
        {this.props.children}
      </div>
    );
  }
}
 
export default Warning;
