import React from 'react'
import axios from "axios"
import { API_BASE } from '../constants';

class NewPrinterPage extends React.Component {
  state = {
    token: ""
  }

  createPrinter = () => {
    axios.post(`${API_BASE}/api/printers`, `token=${this.state.token}`, {
      headers: { "Content-Type": "application/x-www-form-urlencoded" }
    })
  }

  updateToken = ev => {
    this.setState({ token: ev.target.value })
  }

  render() {
    return (
      <div className="page">
        <h1>New Printer</h1>

        <div className="form-field">
          Create a new emulated printer authenticated with the specified token.
        </div>

        <div className="form-field">
          <label htmlFor="token">Token: </label>
          <input id="token" type="text" value={this.state.token} onChange={this.updateToken}></input>
        </div>

        <button onClick={this.createPrinter}>Create Printer</button>
      </div>
    )
  }
}

export default NewPrinterPage;
