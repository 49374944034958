import React from 'react';
import { Link } from 'react-router-dom';

function IndexPage() {
  return (
    <div className="page">
      <h1>Welcome</h1>

      <div>
        To get started, <Link to="/new">create a printer</Link>. You can also <a href="https://github.com/makerbot/printemu/wiki/Web-UI" target="_blank" rel="noreferrer">read the docs</a>.
      </div>
    </div>
  );
}

export default IndexPage;
