import { EventEmitter } from "events"

const EVENT_DISCONNECTED = "DISCONNECTED"
const EVENT_CONNECTED = "CONNECTED"

class Socket extends EventEmitter {
  private ws: WebSocket

  constructor(url: string) {
    super()

    this.ws = new WebSocket(url)
    this.ws.onmessage = this.handleMessage.bind(this)
    this.ws.onopen = this.handleConnect.bind(this)
    this.ws.onclose = this.handleDisconnect.bind(this)
    this.ws.onerror = this.handleDisconnect.bind(this)
  }

  private handleConnect() {
    this.emit(EVENT_CONNECTED)
  }

  private handleDisconnect() {
    this.emit(EVENT_DISCONNECTED)
  }

  private handleMessage(msg: MessageEvent) {
    let json = JSON.parse(msg.data)
    this.emit(json.type, json.data)
  }
}

export {
  Socket,
  EVENT_DISCONNECTED,
  EVENT_CONNECTED
}
