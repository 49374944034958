import * as React from 'react';
import { Component } from 'react';
import { Link } from 'react-router-dom';
import "./sidebarItem.scss";

class SidebarItem extends Component {
  render() {
    return (
      <Link to={this.props.to} onClick={this.props.onSelect} className={`sidebar-item ${this.props.active ? "selected" : ""}`}>
        {this.props.title}
      </Link>
    );
  }
}
 
export default SidebarItem;
