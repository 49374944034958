export const API_BASE = process.env.REACT_APP_API_BASE 
  ? `${process.env.REACT_APP_API_PROTOCOL}${process.env.REACT_APP_API_BASE}` 
  : "";


export const PRINTERS = [
    { family: 'replicator', value: 'mini_4', label: 'Replicator Mini' },
    { family: 'replicator', value: 'mini_8', label: 'Replicator Mini+' },
    { family: 'replicator', value: 'replicator2', label: 'Replicator 2' },
    { family: 'replicator', value: 'replicator2x', label: 'Replicator 2X' },
    { family: 'replicator', value: 'replicator_5', label: 'Replicator 5th Gen' },
    { family: 'replicator', value: 'replicator_b', label: 'Replicator+' },
    { family: 'replicator', value: 'z18_6', label: 'Replicator Z18' },
    { family: 'method', value: 'fire_e', label: 'METHOD' },
    { family: 'methodx', value: 'lava_f', label: 'METHOD X' },
    { family: 'methodx', value: 'magma_10', label: 'METHOD XL' },
    { family: 'sketch', value: 'sketch', label: 'Sketch' },
    { family: 'sketch', value: 'sketch_large', label: 'Sketch Large' },
];
  
export const EXTRUDERS = [
    { family: 'none', value: 0, label: 'None' },
    { family: 'replicator', value: 1, label: 'Smart Extruder' },
    { family: 'replicator', value: 8, label: 'Smart Extruder+' },
    { family: 'replicator', value: 14, label: 'Tough Smart Extruder+ (IM-PLA)' },
    { family: 'sketch', value: 98, label: 'Sketch Extruder' },
    { family: 'replicator', value: 99, label: 'Labs Experimental Extruder' },
    { family: 'method', value: 100, label: 'Model 1A Performance Extruder' },
    { family: 'method', value: 101, label: 'Support 2A Performance Extruder' },
    { family: 'methodx', value: 102, label: 'Model 1XA Performance Extruder' },
    { family: 'methodx', value: 103, label: 'Support 2XA Performance Extruder' },
    { family: 'method', value: 104, label: 'Labs Experimental Extruder for Method' },
    { family: 'method', value: 105, label: 'Model 1C Composites Extruder' },
];