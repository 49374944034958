import * as React from 'react';
import { Component } from 'react';
import { Redirect } from 'react-router';
import "./printerPage.scss"
import { duration } from "moment"
import axios from "axios"
import { API_BASE, PRINTERS, EXTRUDERS } from '../constants';

class PrinterPage extends Component {
  state = {
    editMode: false,
    draftPrinter: null,
    confirmDelete: false
  }

  constructor(props) {
    super(props)

    this.setState({
      confirmDelete: false
    })
  }

  get printer() {
    if(this.state.editMode && this.state.draftPrinter)
      return this.state.draftPrinter

    return (
      this.props.printers && this.props.match.params.id ?
        this.props.printers.filter(p => p.id === this.props.match.params.id)[0] :
        null
    )
  }

  toggleEditMode = () => {
    let draftPrinter = { }
    Object.assign(draftPrinter, this.printer)

    this.setState({
      editMode: !this.state.editMode,
      draftPrinter
    })
  }

  updateExtruder = ev => {
    axios.post(`${API_BASE}/api/printers/${this.printer.id}/extruder`, `extruder_type=${ev.target.value}`, {
      headers: { "Content-Type": "application/x-www-form-urlencoded" }
    })
    this.state.draftPrinter.state.toolheads.extruder[0].tool_id = parseInt(ev.target.value)
    this.setState({ draftPrinter: this.state.draftPrinter })
  }

  updateBot = ev => {
    axios.post(`${API_BASE}/api/printers/${this.printer.id}/bot`, `bot_type=${ev.target.value}`, {
      headers: { "Content-Type": "application/x-www-form-urlencoded" }
    })
    this.state.draftPrinter.state.bot_type = ev.target.value
    this.setState({ draftPrinter: this.state.draftPrinter })
  }

  deletePrinter = () => {
    if(!this.state.confirmDelete) {
      this.setState({ confirmDelete: true })
    } else {
      axios.delete(`${API_BASE}/api/printers/${this.printer.id}`)
    }
  }

  rename = ev => {
    this.state.draftPrinter.state.machine_name = ev.target.value
    this.setState({ draftPrinter: this.state.draftPrinter })

    axios.post(`${API_BASE}/api/printers/${this.printer.id}/name`, `name=${encodeURIComponent(this.state.draftPrinter.state.machine_name)}`, {
      headers: { "Content-Type": "application/x-www-form-urlencoded" }
    })
  }

  startPrint = () => {
    axios.post(`${API_BASE}/api/printers/${this.printer.id}/print`)
  }

  suspendPrint = () => {
    axios.post(`${API_BASE}/api/printers/${this.printer.id}/suspend`)
  }

  goIdle = () => {
    axios.post(`${API_BASE}/api/printers/${this.printer.id}/idle`)
  }

  changeStep = ev => {
    this.state.draftPrinter.state.current_process.step = ev.target.value
    this.setState({ draftPrinter: this.state.draftPrinter })

    axios.post(`${API_BASE}/api/printers/${this.printer.id}/step`, `step=${encodeURIComponent(ev.target.value)}`, {
      headers: { "Content-Type": "application/x-www-form-urlencoded" }
    })
  }

  render() {
    if(!this.printer) {
      return <Redirect to="/"/>
    }
    
    let printControls
    let printer = this.printer
    const printerFamily = PRINTERS.find(p => p.value === printer.state.bot_type)?.family;

    if(printer.state.current_process?.name === "PrintProcess") {
      let elapsed = duration(printer.state.current_process.elapsed_time, "seconds")
      let remaining = duration(printer.state.current_process.time_remaining, "seconds")

      printControls = (
        <div>
          <div className="form-field">
            Current File: {printer.state.current_process.filename}
            {/* <label htmlFor="current_print">File Name: </label> */}
            {/* <input id="current_print" type="text" value={printer.state.current_process.filename} disabled={!this.state.editMode}></input> */}
          </div>

          <div className="form-field">
            <label htmlFor="print_step">Step: </label>
            <select id="print_step" onChange={this.changeStep} value={printer.state.current_process.step} disabled={!this.state.editMode}>
              <option value="initializing">Initializing</option>
              <option value="printing">Printing</option>
              <option value="suspended">Suspended</option>
              <option value="cleaning_up">Cleaning Up</option>
              <option value="completed">Completed</option>
              <option value="failed">Failed</option>
            </select>
          </div>
          
          <div className="form-field">
            Progress: {printer.state.current_process.progress}%
          </div>

          <div className="form-field">
            Time Elapsed: {elapsed.humanize()} ({elapsed.asSeconds()} seconds)
          </div>

          <div className="form-field">
            Time Remaining: {remaining.humanize()} ({remaining.asSeconds()} seconds)
          </div>
        </div>
      )
    }

    return (
      <div className="page printer-page">
        <h1>Printer State</h1>

        <button onClick={this.toggleEditMode}>
          {this.state.editMode && "Save State"}
          {!this.state.editMode && "Edit State"}
        </button>

        <button onClick={this.deletePrinter}>
          {this.state.confirmDelete && "Really? (click again to confirm)"}
          {!this.state.confirmDelete && "Delete Printer"}
        </button>

        <div className="form-field">
          Enabling edit mode will temporarily "freeze" the state of the printer so you can edit it
          without interruption.
        </div>

        <h2>Bot Metadata</h2>

        <div className="form-field">
          <label htmlFor="bot_name">Bot Name: </label>
          <input id="bot_name" type="text" onChange={this.rename} value={printer.state.machine_name} disabled={!this.state.editMode}></input>
        </div>

        <div className="form-field">
          <label htmlFor="bot_type">Bot Type: </label>
          <select id="bot_type" onChange={this.updateBot} value={printer.state.bot_type} disabled={!this.state.editMode}>
            {PRINTERS.map(printer => (
              <option key={printer.value} value={printer.value}>{printer.label}</option>
            ))}
          </select>
        </div>

        <div className="form-field">
          <label htmlFor="extruder">Extruder: </label>
          <select id="extruder" onChange={this.updateExtruder} value={printer.state.toolheads.extruder[0].tool_id} disabled={!this.state.editMode}>
            {EXTRUDERS.filter(extruder => extruder.family === 'none' || extruder.family === printerFamily || (printerFamily === 'methodx' && extruder.family === 'method')).map(extruder => (
              <option key={extruder.value} value={extruder.value}>{extruder.label}</option>
            ))}
          </select>
        </div>

        <h2>Print Control</h2>

        {printControls}

        <button onClick={this.startPrint} disabled={this.state.editMode || printer.state.current_process?.step === "printing"}>Start Print</button>
        <button onClick={this.suspendPrint} disabled={this.state.editMode || !printer.state.current_process || printer.state.current_process?.step === "suspended"}>Suspend Print</button>
        <button onClick={this.goIdle} disabled={this.state.editMode || !printer.state.current_process}>Stop Print (Go Idle)</button>
      </div>
    );
  }
}
 
export default PrinterPage;
