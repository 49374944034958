import * as React from 'react';
import { Component } from 'react';
import SidebarItem from './sidebarItem';
import "./sidebar.scss";
import { Link } from 'react-router-dom';

class Sidebar extends Component {
  render() {
    let items = this.props.sidebarItems.map((item, index) => {
      return (
        <SidebarItem
          key={item.id}
          active={window.location.pathname === item.to}
          onSelect={() => { this.forceUpdate() }}
          {...item}
        />
      );
    });
    
    return (
      <div className="sidebar">
        <h2>
          Mock Printers
          <Link to="/new" title="New Printer">+</Link>
        </h2>
        {items}
      </div>
    );
  }
}
 
export default Sidebar;
